import { Tooltip as RCTooltip } from "react-tooltip";

import classNames from "classnames";
import classes from "./Tooltip.module.scss";

import V1White from "../../../public/icons/v1_white.svg";
import { useEffect, useRef, useState } from "react";

interface Props extends Omit<React.HTMLProps<HTMLDivElement>, "ref"> {
  id: string;
  message: string;
  showIcon: true | false;
  isOpen?: boolean;
  title?: string;
  position?: string;
  signature?: string;
}

const Tooltip = ({
  className,
  id,
  message,
  showIcon,
  isOpen,
  title,
  position,
  signature,
  ...htmlProps
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showBottomArrow, setShowBottomArrow] = useState<boolean>(false);

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      setShowBottomArrow(
        container.scrollTop < container.scrollHeight - container.clientHeight
      );
    }
  };
  
  useEffect(() => {
    handleScroll()
  }, [isOpen]);
  

  return (
    <RCTooltip
      id={id}
      isOpen={isOpen}
      opacity={100}
      clickable
      className={classNames(classes.tooltip, className)}
      classNameArrow={classes.tooltip__arrow}
      positionStrategy="absolute"
      {...htmlProps}
    >
      {showBottomArrow && (
        <div className={classes.tooltip__containerArrow} />
      )}
      <div className={classes.tooltip__container} onScroll={handleScroll} ref={containerRef}>
        {title && (
          <div className={classes.tooltip__title}>
            <p className="m-0">{title}</p>
          </div>
        )}
        {position && (
          <div className={classes.tooltip__position}>
            <p className="m-0 opacity-75">{position}</p>
          </div>
        )}
        <div className={classes.tooltip__message}>
          <p className="m-0">{message}</p>
        </div>
        <div className="d-flex justify-content-evenly flex-row">
          <div className="d-flex justify-content-center align-items-center py-2">
            <V1White />
          </div>
          {signature && (
            <div className={classes.tooltip__signature}>
              <p className="m-0">{signature}</p>
            </div>
          )}
        </div>
      </div>
    </RCTooltip>
  );
};

export default Tooltip;
