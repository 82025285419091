import { GetStaticProps, NextPage } from "next";
import { useTranslation } from "next-i18next";
import React from "react";
import Layout from "../components/Layout";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { homepage } from "../routes/namespaces";
import HomePage from "../components/Homepage/HomePage";

const Home: NextPage = () => {
  const { t } = useTranslation("navigation");

  return (
    <Layout
      title={t("about")}
      bgPrimary
      navigationClassName="position-absolute"
    >
      <HomePage />
    </Layout>
  );
};

export default Home;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(
        //locale || "cs",
        "cs",
        homepage
      )),
    },
  };
};
