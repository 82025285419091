import React from "react";
import classNames from "classnames";
import classes from "./HomePage.module.scss";
import Header from "./Header/Header";
import OurClients from "./OurClients/OurClients";
import Contact from "../Contact/Contact/Contact";
import Claims from "./Claims/Claims";
import SpiderWeb from "./Spiderweb/Spiderweb";

interface Props extends React.HTMLProps<HTMLDivElement> {}

const Homepage = ({ className, ...htmlProps }: Props) => {

  return (
    <div {...htmlProps} className={classNames(classes.homepage, className)}>
      <section
        id="section-0"
        className={classNames(classes.homepage__sectionFirst)}
      >
        <SpiderWeb>
          <Header />
        </SpiderWeb>
      </section>
      <section
        id="section-1"
        className={classNames(classes.homepage__section)}
      >
        <Claims isDark={false} />
      </section>
      <section
        id="section-2"
        className={classNames(classes.homepage__section)}
      >
        <Claims isDark />
      </section>
      <section
        id="section-3"
        className={classNames(classes.homepage__section)}
      >
        <OurClients />
      </section>
      <section
        id="section-4"
        className={classNames(classes.homepage__section)}
      >
        <Contact variant="business" showTitlePhrase />
      </section>
    </div>
  );
};

export default Homepage;
