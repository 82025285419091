import RaiffeisenSVG from "../../../public/icons/clients/raiffeisen.svg";
import LmcSVG from "../../../public/icons/clients/lmc.svg";
import LinetSVG from "../../../public/icons/clients/linet.svg";
import KvElektroSVG from "../../../public/icons/clients/KV.svg";
import IbmSVG from "../../../public/icons/clients/ibm.svg";
import TrinitySVG from "../../../public/icons/clients/trinity.svg";
import MarunaSVG from "../../../public/icons/clients/maruna.svg";
import MebsterSVG from "../../../public/icons/clients/mebster.svg";
import VrSVG from "../../../public/icons/clients/VR.svg";
import CzskiSVG from "../../../public/icons/clients/czski.svg";
import FireFlySVG from "../../../public/icons/clients/firefly.svg";
import CezSVG from "../../../public/icons/clients/cez.svg";
import LivesportSVG from "../../../public/icons/clients/livesport.svg";
import AirBankSVG from "../../../public/icons/clients/airbank.svg";
import CNC from "../../../public/icons/clients/cnc.svg";
import MeroSVG from "../../../public/icons/clients/mero.svg";
import CeskaSporitelnaSVG from "../../../public/icons/clients/ceskasporitelna.svg";
import CSOBSVG from "../../../public/icons/clients/csob.svg";
import KosikSVG from "../../../public/icons/clients/kosik.svg";

export const ourClientsData = [
  <RaiffeisenSVG title="Raiffeisen" key="raiffeisen"/>,
  <LmcSVG title="LMC" key="lmc" />,
  <LinetSVG title="Linet" key="linet" />,
  <KvElektroSVG title="KV Elektro" key="kvelektro" />,
  <IbmSVG title="IBM" key="ibm" />,
  <TrinitySVG title="Trinity" key="trinity" />,
  <MarunaSVG title="Maruna" key="maruna" />,
  <MebsterSVG title="Mebster" key="mebster" />,
  //anume
  <VrSVG title="VR medical" key="vr-medical" />,
  <CzskiSVG title="CZSKI" key="czski"/>,
  <FireFlySVG title="Firefly" key="firefly" />,
  <CezSVG title="CEZ" key="cez" />,
  <LivesportSVG title="Livesport" key="livesport" />,
  <CNC title="CNC" key="cnc" />,
  <AirBankSVG title="Airbank" key="airbank" />,
  <MeroSVG title="Mero" key="mero" />,
  <CeskaSporitelnaSVG title="CeskaSporitelna" key="ceska-sporitelna" />,
  <CSOBSVG title="CSOB" key="csob" />,
  <KosikSVG title="Kosik" key="kosik" />,
];
