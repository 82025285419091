import React from "react";
import dynamic from "next/dynamic";
import classes from "./SpiderWeb.module.scss";

const Particles = dynamic(() => import("./Particles"), {
  ssr: false,
});

interface Props extends React.HTMLProps<HTMLDivElement> { }

const SpiderWeb = ({ children, ...htmlProps }: Props) => {
  return (
    <div {...htmlProps}>
      <>
        <div className={classes.spiderweb}>
          <Particles />
        </div>
        {children}
      </>
    </div>
  );
};

export default SpiderWeb;
