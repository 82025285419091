import React from "react";
import classNames from "classnames";
import classes from "./Claims.module.scss";
import { useTranslation } from "next-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { ReactBootstrapHTMLContainerProps } from "../../../types/htmlElementTypes";
import Image from "next/legacy/image";
import CornerIMG from "../../../public/images/homepage/claim1.webp";
import StatsIMG from "../../../public/images/homepage/claim2.png";

interface Props extends ReactBootstrapHTMLContainerProps {
  isDark: boolean;
}

const Claims = ({ className, isDark, ...htmlProps }: Props) => {
  const { t } = useTranslation("homepage");

  const claimsClass = "claims" + (isDark ? "Dark" : "");

  const claimsContainerClass = "claims__container" + (isDark ? "Dark" : "");

  const claimsTitles = [
    "claims.titles.first_item",
    "claims.titles.second_item",
    "claims.titles.third_item",
  ];

  const claimsTitlesDark = [
    "claims.titles.fourth_item",
    "claims.titles.fifth_item",
    "claims.titles.sixth_item",
  ];

  const claimsTextation = [
    "claims.first_item",
    "claims.second_item",
    "claims.third_item",
  ];

  const claimsTextationDark = [
    "claims.fourth_item",
    "claims.fifth_item",
    "claims.sixth_item",
  ];

  return (
    <div
      {...htmlProps}
      className={classNames(
        classes[claimsClass],
        isDark && "bg-dark",
        className
      )}
    >
      <Container
        className={classNames(classes[claimsContainerClass], className)}
      >
        <Row
          className={classNames(
            "justify-content-between align-items-center py-6 gx-10",
            isDark ? "flex-column flex-md-row" : "flex-row flex-lg-row-reverse"
          )}
        >
          <Col xs={12} lg={6} className={classes.claims__columnImage}>
            <Image
              src={isDark ? StatsIMG : CornerIMG}
              alt={isDark ? "Analytical work on laptop" : "Coding in office"}
            />
          </Col>
          <Col xs={12} lg={6} className={classes.claims__column}>
            {(isDark ? claimsTextationDark : claimsTextation).map(
              (claim, index) => (
                <div
                  key={`claims-item-${index}`}
                  className={classes.claims__item}
                  id={"claim-" + index}
                >
                  <Row className="align-items-center gap-5">
                    <Col
                      className={
                        isDark
                          ? classes.claims__numberDark
                          : classes.claims__number
                      }
                    >{`➔`}</Col>
                    <Col className="flex-grow-1 p-0">
                      <div
                        className={
                          isDark
                            ? classes.claims__titleDark
                            : classes.claims__title
                        }
                      >
                        {t(
                          isDark ? claimsTitlesDark[index] : claimsTitles[index]
                        )}
                      </div>
                      <div
                        className={
                          isDark
                            ? classes.claims__textationDark
                            : classes.claims__textation
                        }
                      >
                        {t(claim)}
                      </div>
                    </Col>
                  </Row>
                </div>
              )
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Claims;
