import { useTranslation } from "next-i18next";
import { Button, Col, Container, Row } from "react-bootstrap";
import classNames from "classnames";
import classes from "./OurClients.module.scss";
import TranslatedLink from "../../utilities/TranslatedLink";
import Tooltip from "../../utilities/Tooltip/Tooltip";
import useWindowDimensions from "components/hooks/useWindowDimension";

import { ComponentPropsWithoutRef, useEffect, useState } from "react";
import { ourClientsData } from "./OurClients.data";

interface Props extends ComponentPropsWithoutRef<"div"> {
  hideReferenceButton?: boolean;
}

const OurClients = ({ className, hideReferenceButton }: Props) => {
  const { t } = useTranslation("clients");
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [activeTooltipIndex, setActiveTooltipIndex] = useState<number>(-1);
  const { width } = useWindowDimensions();
  const smallOrLower: boolean = width < 768;

  const showRandomTooltip = () => {
    if (!isSelected) {
      setActiveTooltipIndex(Math.floor(Math.random() * ourClientsData.length));
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (!smallOrLower) {
      interval = setInterval(() => {
        showRandomTooltip();
      }, 7000);
    }
    return () => clearInterval(interval);
  }, [activeTooltipIndex, smallOrLower]);

  return (
    <div className={classNames(classes.ourClients, className)}>
      <Container className={classes.ourClients__container}>
        <h2 className="text-uppercase my-5 mt-10 hoverable">{t("title")}</h2>
        <Row className="d-flex justify-content-center align-items-center my-5">
          {ourClientsData.map((client, index) => (
            <Col
              xs={6}
              sm={4}
              lg={3}
              key={`client-${index}`}
              className={classNames("justify-content-center py-3")}
              onMouseEnter={() => {
                setActiveTooltipIndex(index);
                setIsSelected(true);
              }}
              onMouseLeave={() => {
                setActiveTooltipIndex(-1);
                setIsSelected(false);
              }}
            >
              <Tooltip
                id={`client-${index}`}
                isOpen={index === activeTooltipIndex}
                message={t(`citations.${client.props.title}.message`, {
                  ns: "clients",
                })}
                signature={t(`citations.${client.props.title}.signature`, {
                  ns: "clients",
                })}
                color="dark"
                showIcon
                onMouseEnter={() => {
                  setActiveTooltipIndex(index);
                }}
              />
              <div
                className={classNames(
                  classes.ourClients__logo,
                  index === activeTooltipIndex &&
                    classes.ourClients__logoColored
                )}
                data-tooltip-id={`client-${index}`}
              >
                {client}
              </div>
            </Col>
          ))}
        </Row>
        <div className="d-flex justify-content-center mt-8">
          <h4 className="text-dark m-0">{t("more")}</h4>
        </div>
        {!hideReferenceButton && (
          <div className="my-6">
            <TranslatedLink href="/case-studies">
              <Button className={classes.ourClients__button} variant="primary">
                {t("case_studies")}
              </Button>
            </TranslatedLink>
          </div>
        )}
      </Container>
    </div>
  );
};

export default OurClients;
