import { Col, Container } from "react-bootstrap";
import { useTranslation } from "next-i18next";
import React from "react";
import classNames from "classnames";
import classes from "./Header.module.scss";
import dynamic from "next/dynamic";

const Carousel = dynamic(() => import("../Carousel/Carousel"), {
  ssr: true,
});

interface Props extends React.HTMLProps<HTMLDivElement> { }

const Header = ({ className, ...htmlProps }: Props) => {
  const { t } = useTranslation("common");

  return (
    <div {...htmlProps} className={classNames(classes.header, className)}>
      <Col className="d-flex flex-column align-items-center">
        <Container className={classes.header__container}>
          <div>
            <h1 className="display-5 fw-bold mb-3 text-uppercase">
              {t("title")}
            </h1>
            <p className="display-6 text-black">{t("subtitle")}</p>
          </div>
        </Container>
        <Carousel />
      </Col>
    </div>
  );
};

export default Header;
